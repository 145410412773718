import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Avatar, Button, FlexRow, FlexSpacedColumn, Text, colors, spacing } from '@orus.eu/pharaoh'
import type { Permission } from '@orus.eu/right-access-management'
import { isSubsetOf } from '@orus.eu/sets'
import { useNavigate, useParams } from '@tanstack/react-router'
import { memo, type ReactNode } from 'react'
import { useSession } from '../../lib/session'
import { OrusIconSvg } from '../atoms/orus-icon-svg'
import { PartnerMenu } from '../organisms/partner-menu'
import { InviteToLoginPage } from './invite-to-login/InviteToLoginPage'
import { MenuPage } from './menu-page'

const menuOrusIconWidthInPx = 28.92

const PartnerPage = memo<{
  children: ReactNode
  requiredPermissions: Permission[]
}>(function PartnerPage({ children, requiredPermissions }) {
  const session = useSession()
  const { organization } = useParams({ strict: false })
  const navigate = useNavigate()

  if (!session) return <InviteToLoginPage />

  // If the user is a client, they should not be able to access the backoffice
  if (session.permissions?.type === 'client') return <InviteToLoginPage />

  // If the user is a platform user, they can access the partner pages, with their platform perms applied
  if (
    session.permissions?.type === 'platform' &&
    isSubsetOf(
      new Set(requiredPermissions),
      new Set(session.permissions.rolesPermissions.flatMap((role) => role.permissions)),
    )
  )
    return <InviteToLoginPage />

  // If the user is a partner user, they can access the partner pages, with their partner perms applied
  if (session.permissions?.type === 'partner') {
    // ensures there's at least one organization for the member
    if (session.permissions.memberships.length === 0) return <InviteToLoginPage />
    // if the member cannot access to requested organization, redirect to the first one
    if (session.permissions.memberships.some(({ organization: { technicalName } }) => technicalName !== organization))
      void navigate({
        to: '/partner/$organization/home',
        params: { organization: session.permissions.memberships[0].organization.technicalName },
      })

    // checks that the user has the proper permissions
    if (
      !isSubsetOf(
        new Set(requiredPermissions),
        new Set(
          session.permissions.memberships
            .filter(({ organization: { technicalName } }) => organization === technicalName)
            .flatMap(({ rolesPermissions }) => rolesPermissions.flatMap(({ permissions }) => permissions)),
        ),
      )
    )
      return <InviteToLoginPage />
  }

  return (
    <MenuPage
      menuTitle={<PartnerMenuTitle />}
      menuBody={
        <div
          css={css`
            padding: 0 ${spacing[30]} ${spacing[30]} ${spacing[30]};
            width: 240px;
          `}
        >
          <PartnerMenu />
        </div>
      }
      pageContent={children}
      border={`1px solid ${colors.gray[100]}`}
    />
  )
})
export default PartnerPage

export const PartnerMenuTitle = memo(function PartnerMenuTitle() {
  const navigate = useNavigate()
  const { permissions } = useSession()
  const { organization } = useParams({ strict: false })

  const partnerOrganizationName =
    // if we're a partner user we get the name from the session
    permissions.type === 'partner'
      ? (permissions.memberships.find(({ organization: { technicalName } }) => organization === technicalName)
          ?.organization?.displayName ?? organization)
      : // if we're a platform user we can't, so we show the ID
        organization

  if (!organization) return <>Organization cannot be empty, the error is caught in the enclosing component</>

  const navigateToSearch = () => void navigate({ to: '/partner/$organization/search', params: { organization } })

  return (
    <FlexSpacedColumn>
      <TitleRow>
        <OrusIconSvg widthPx={menuOrusIconWidthInPx} />
        <Button
          variant="secondary"
          size="small"
          avatar={<Avatar icon="magnifying-glass-solid" />}
          onClick={navigateToSearch}
          css={css`
            border-radius: 100%;
          `}
        />
      </TitleRow>
      <OrganizationNameRow>
        <Text variant="subtitle2">{partnerOrganizationName}</Text>
      </OrganizationNameRow>
    </FlexSpacedColumn>
  )
})

const TitleRow = styled(FlexRow)`
  justify-content: space-between;
  padding: ${spacing[50]} 0;
  width: 100%;
`

const OrganizationNameRow = styled(FlexRow)`
  padding: ${spacing[50]} 0;
  margin: 0;
  max-width: 200px;

  & > p {
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
  }
`
