import styled from '@emotion/styled'
import { FlexColumn, Icon, Text, colors, spacing } from '@orus.eu/pharaoh'
import { useParams } from '@tanstack/react-router'
import { type ReactNode } from 'react'
import { DisconnectButtonLink } from '../molecules/disconnect-button-link'
import { NavMenuItem } from '../molecules/nav-menu-item'
import { navMenuItemSx } from '../molecules/nav-menu-item-style'

export function PartnerMenu(): ReactNode {
  const { organization } = useParams({ strict: false })

  if (!organization) return <>Organization cannot be empty, the error is caught in the enclosing component</>

  return (
    <nav
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <FlexColumn gap={spacing[20]} margin={`${spacing[70]} 0`} padding="0 0">
        <NavMenuItem
          to="/partner/$organization/home"
          params={{ organization }}
          icon="house-solid"
          label="Accueil"
          variant="backoffice"
        />
        <NavMenuItem
          to="/partner/$organization/pending-subscriptions"
          params={{ organization }}
          icon="clock-three-solid"
          label="Devis"
          variant="backoffice"
        />
        <NavMenuItem
          to="/partner/$organization/users"
          params={{ organization }}
          icon="folder-user-solid"
          label="Clients"
          variant="backoffice"
        />
      </FlexColumn>

      <FlexColumn gap={spacing[50]} margin={`${spacing[70]} 0`} padding="0 0">
        <PartnerDisconnectButton />
      </FlexColumn>
    </nav>
  )
}

function PartnerDisconnectButton() {
  return (
    <DisconnectButtonLink sx={navMenuItemSx}>
      <FlexCenter>
        <Icon icon="arrow-right-to-bracket-solid" color={colors.gray[700]} />
      </FlexCenter>
      <Text variant="body2Medium">Se déconnecter</Text>
    </DisconnectButtonLink>
  )
}

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${spacing[50]};
  height: ${spacing[50]};
`
